import React from 'react';
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga';

function UserManualLink(props) {
  const { t } = useTranslation();
  const { product } = props;
  const link = product.getAttribute('link_usermanual_web').value ||
    product.getAttribute('link_usermanual').value;
  const btn_label = product.getAttribute('link_usermanual_web').value ? t('See user manual') : t('Download user manual')
  if (link === null || link === "") {
    return null;
  }

  const click = () => {
    ReactGA.event({
      category: 'Téléchargements',
      action: 'User manual'
    });
  };

  return (
    <a
      className="btn"
      onClick={click}
      href={ link }
      rel="noopener noreferrer"
      target="_blank">
      { btn_label }
    </a>
  );
}

export default UserManualLink;
