import MerchandisingMenu from 'pages/merchandising/MerchandisingMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import imgNoResults from 'assets/faq-no-result.jpg';
import imgNew from 'assets/faq-new.jpg';
import imgLangMenu from 'assets/faq-language-menu.jpg';
import imgStoreMenu from 'assets/faq-store-menu.jpg';
import imgRefOrder from 'assets/faq-ref-order.jpg';
import imgCurCol from 'assets/faq-current-col.jpg';
import imgWhenProducts from 'assets/faq-when-products-appear.jpg';
import imgInStock from 'assets/faq-in-stock.jpg';
import imgPrice from 'assets/faq-price.jpg';

function FaqElement(props) {
  const { id, img, title, content, position } = props;
  return (
    <div className="faq-answer" id={id}>
      <div className="grid">
        <div className="col-1">
          { img && position === 'left' && (
            <img className="left" src={img} alt=""/>
          ) }
        </div>
        <div className="col-3">
          <h4>{ title }</h4>
          { content }
        </div>
      </div>
      { img && position === 'bottom' && (
        <div><img className="bottom" src={img} alt=""/></div>
      ) }
    </div>
  );
}
FaqElement.defaultProps = {
  position: 'left'
};

function Faq() {
  const { t } = useTranslation();

  const questions = [
    { id: 'language-menu',
      img: imgLangMenu,
      title: t('faq.language-menu.title'),
      content: t('faq.language-menu.content') },
    { id: 'store-menu',
      img: imgStoreMenu,
      title: t('faq.store-menu.title'),
      content: t('faq.store-menu.content') },
    { id: 'current-col-order',
      img: imgCurCol,
      title: t('faq.current-col-order.title'),
      content: t('faq.current-col-order.content') },
    { id: 'which-product',
      title: t('faq.which-product.title'),
      content: t('faq.which-product.content') },
    { id: 'references-ordered',
      img: imgRefOrder,
      title: t('faq.references-ordered.title'),
      content: t('faq.references-ordered.content') },
    { id: 'when-products-appear',
      img: imgWhenProducts,
      title: t('faq.when-products-appear.title'),
      content: t('faq.when-products-appear.content') },
    { id: 'in-stock',
      img: imgInStock,
      title: t('faq.in-stock.title'),
      content: t('faq.in-stock.content') },
    { id: 'new-meaning',
      img: imgNew,
      title: t('faq.new-meaning.title'),
      content: t('faq.new-meaning.content') },
    { id: 'price-display',
      img: imgPrice,
      title: t('faq.price-display.title'),
      content: t('faq.price-display.content') },
    { id: 'prices-update',
      title: t('faq.prices-update.title'),
      content: t('faq.prices-update.content') },
    { id: 'reference-no-results',
      img: imgNoResults, position: 'bottom',
      title: t('faq.reference-no-results.title'),
      content: t('faq.reference-no-results.content') },
  ];
  const menu = [];
  for (const q of questions) {
    menu.push({ id: q.id, text: t(`faq.${q.id}.title`) })
  }

  return (
    <div className="faq contents-page">
      <h2 className="main">{ t('Frequently asked questions') }</h2>
      <MerchandisingMenu elements={menu}/>
      <div className="content content-grules" id="content-rules">
        { questions.map(q => (
          <FaqElement key={q.id} {...q} />
        )) }
      </div>
    </div>
  );
}

export default Faq;
