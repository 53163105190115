import React from 'react';
import 'app.css';
import { BrowserRouter, Route, Link, withRouter, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import NavBar from 'components/nav/NavBar';
import ProductsPage from 'pages/ProductsPage';
import ProductPage from 'pages/ProductPage';
import StrapDetailPage from 'pages/StrapDetailPage';
import CustomerServicesPage from 'pages/CustomerServicesPage';
import SearchPage from 'pages/SearchPage';
import AboutTissotPage from 'pages/AboutTissotPage';
import AboutPage from 'pages/AboutPage';
import TechnicalFeaturesPage from 'pages/TechnicalFeaturesPage';
import MerchandisingPage from 'pages/MerchandisingPage';
import StrapPage from 'pages/StrapPage';
import StrapsPage from 'pages/StrapsPage';
import StrapsPageSku from 'pages/StrapsPageSku';
import HomePage from 'pages/HomePage';
import AddHomeScreen from 'components/AddHomeScreen';
import NoveltiesPage from 'pages/NoveltiesPage';
import AppContext from 'utils/AppContext';
import { useTranslation } from 'react-i18next';

function FooterContent() {
  const { t } = useTranslation();
  return (
    <>
      © TISSOT SA - SWISS WATCHES SINCE 1853 - ALL RIGHT RESERVED
      <Link className="sidelink" to="/about" >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M68,19a8,8,0,1,0,8,8,8,8,0,0,0-8-8m0,18A10,10,0,1,1,78,27,10,10,0,0,1,68,37"
            transform="translate(-57.95 -17)"
            fill="#ffffff"/>
          <path d="M66.24,32.06V21.94L71.3,27Z" transform="translate(-57.95 -17)" fill="#ffffff"/>
        </svg>
        {t('About this tool')}
      </Link>
    </>
  );
}

class BaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.isFirstLoad = true;
    this.isVersionMismatch = props.isVersionMismatch;
  }

  setBodyClasses() {
    const parts = this.props.location.pathname.substring(1).split('/');
    document.body.removeAttribute('class');
    document.body.classList.add((parts[0] ? parts[0] : 'front'));
    if (parts.length > 1) {
      document.body.classList.add(parts.join('-'));
    }
  }

  componentDidMount() {
    this.setBodyClasses();
    this.isFirstLoad = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.set({ page: this.props.location.pathname });
      ReactGA.pageview(this.props.location.pathname);
      this.setBodyClasses();
    }
  }

  render() {
    /* eslint-disable react/no-unescaped-entities */
    const isBack = this.isFirstLoad ? false : this.props.history.action === 'POP';
    if (this.isVersionMismatch) {
      console.error('isVersionMismatch');
      return null;
    }
    return (
      <div className="base">
        <header>
          <NavBar/>
        </header>
        <div className="main">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/about_tissot/:section" component={AboutTissotPage} />
            <Route path="/technical_features" component={TechnicalFeaturesPage} />
            <Route path="/merchandising" component={MerchandisingPage} />
            <Route path="/straps-page" component={StrapPage} />
            <Route path="/customer-services" component={CustomerServicesPage} />
            <Route
              path="/collection"
              exact
              render={(props) => (
                <ProductsPage
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route
              path="/collection/:collection"
              render={(props) => (
                <ProductsPage
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route
              path="/novelties/:pk?"
              render={(props) => (
                <NoveltiesPage
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route
              path="/search"
              render={(props) => (
                <SearchPage
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route
              path="/straps-fromwatch/:sku"
              render={(props) => (
                <StrapsPageSku
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route
              path="/straps"
              render={(props) => (
                <StrapsPage
                  {...props}
                  isBackBtn={isBack}
                  filtersHash={this.context.getFiltersHash()} />
              )} />
            <Route path="/strap-detail/:sku" component={StrapDetailPage} />
            <Route path="/product/:sku" component={ProductPage} />
            <Route path="/about" exact component={AboutPage} />
          </Switch>
        </div>
        <footer>
          <FooterContent />
        </footer>
      </div>
    );
  }
}
BaseLayout.contextType = AppContext;

const BaseLayoutRouter = withRouter(BaseLayout);

function App(props) {
  return (
    <BrowserRouter>
      <BaseLayoutRouter isVersionMismatch={props.isVersionMismatch} />
      <AddHomeScreen />
    </BrowserRouter>
  );
}

export default App;
