/* eslint-disable react/no-unused-state, react/no-danger */
/*
 * Additional translations:
 * t('stock.green')
 * t('stock.grey')
 * t('stock.yellow')
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';
import TechnicalSpecs from 'components/product/TechnicalSpecs';
import CustomerServiceLink from 'components/product/CustomerServiceLink';
import StrapsSearchLink from 'components/product/StrapsSearchLink';
import UserManualLink from 'components/product/UserManualLink';
import OtherFamilyProducts from 'components/product/OtherFamilyProducts';
import RelatedProducts from 'components/product/RelatedProducts';
import ProductPrice from 'components/product/ProductPrice';
import ProductStock from 'components/product/ProductStock';
import ProductImages from 'components/product/ProductImages';
import Watch from 'models/Watch';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import AppContext from 'utils/AppContext';
import SeparatedTitle from 'components/SeparatedTitle';
import shadowlightbig from 'assets/shadowlightbig.jpg';
import Barcode from 'components/Barcode';
import Pictos from 'components/Pictos';
import SoldierImage from 'components/SoldierImage';
import ProductNovelty from 'components/product/ProductNovelty';
import SampleToOrder from 'components/product/SampleToOrder';
import Breadcrumb from 'components/nav/Breadcrumb';

class ProductPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: null,
      lang: i18n.language,
      other_family_products: null,
    };
  }

  load() {
    const { country, token } = this.context;
    this.product = new Watch(null, country, token);
    this.product.sku = this.props.match.params.sku;
    this.product.lang = this.state.lang;
    this.product.fetch(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.sku !== prevProps.match.params.sku
    ) {
      this.setState({ isLoaded: false });
      this.load();
    }
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load();
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  render() {
    const { isLoaded } = this.state;
    const { t } = this.props;

    if (!isLoaded || !this.product || !this.product._data) {
      return <Spinner/>;
    } else {
      const designerWord = this.product.getAttribute('digital_content');
      const limitedEdition = this.product.getAttribute('limited_editionnbpcs');
      let limitedEditionContent = null;
      if (limitedEdition && limitedEdition.value) {
        limitedEditionContent = (
          <div className="limited">
            {t('Limited edition {{ count }} pieces', { count: limitedEdition.value })}
          </div>
        );
      }

      const detailValue = this.product.getAttribute('digital_detail').value;
      const detailValueClean = detailValue ? detailValue.replace(/&nbsp;/g, ' ').replace(/\u00A0/g, ' ') : '';

      return (
        <div className={`watch-full family-${this.product.familyId} watch-${this.product.sku}`}>

          <div className="head">
            <div className="container">
              <Breadcrumb menu={[
                {
                  path: 'collection',
                  name: t('Current collections')
                },
                ...this.product.menu
              ]} />
            </div>
            <div className="container">
              <div className="infos">
                <h1><SeparatedTitle value={this.product.name} /></h1>
                <div className="product-announcements">
                  <ProductNovelty product={this.product}/>
                  <SampleToOrder product={this.product}/>
                </div>
                <p className="main">
                  {this.product.getAttribute('digital_subtitle').value}
                </p>
                <div className="grid wide">
                  <div className="col-2 how-to">
                    { detailValue && (
                      <>
                        <span className="label">
                          { this.product.getAttribute('digital_detail').label }
                        </span>
                        <span
                          className="value"
                          dangerouslySetInnerHTML={{
                            __html: detailValueClean
                          }}>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col-2">
                    {limitedEditionContent}
                    <div className="sku">{this.product.originalSku}</div>
                    <ProductPrice product={this.product}/>
                    <ProductStock product={this.product}/>
                  </div>
                </div>
                <RelatedProducts products={this.product} related={this.product._data.product_group} />

              </div>
              <div className="visual">
                {designerWord.value && (
                  <div className="designer-word">
                    <div className="inside">
                      <span className="title">{designerWord.label}</span>
                      <span dangerouslySetInnerHTML={{ __html: designerWord.value }}></span>
                    </div>
                  </div>
                )}
                <div className="img">
                  {this.product.soldier !== null && (
                    <img className="img-shadow" src={shadowlightbig} alt="" />
                  )}
                  <SoldierImage src={this.product.getSoldierUrl('soldier_full')} />
                  <Pictos product={this.product} view="full" />
                  <Barcode code={this.product.getAttribute('ean').value}/>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="links links-top">
              <CustomerServiceLink product={this.product}/>
              <StrapsSearchLink product={this.product}/>
            </div>
            <TechnicalSpecs product={this.product}/>
            <div className="links">
              <UserManualLink product={this.product}/>
            </div>
            <ProductImages product={this.product}/>
            <OtherFamilyProducts sku={this.product.sku} family={this.product.getFamilyId()} />
          </div>
        </div>
      )
    }
  }
}
ProductPage.contextType = AppContext;

export default withTranslation()(ProductPage);
