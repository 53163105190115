import React, { useState, useEffect } from 'react';
import MessageBox from 'components/MessageBox';
import * as serviceWorker from 'serviceWorkerRegistration';

// t('A new version is available!')
// t('Reload')
const texts = {
  en: {
    refresh: {
      txt: 'A new version is available!',
      reload: 'Reload',
    },
    mismatch: {
      txt: 'The server is being updated, please try again in a few minutes.'
    }
  },
  fr: {
    refresh: {
      txt: 'Une nouvelle version est disponible !',
      reload: 'Rafraîchir',
    },
    mismatch: {
      txt: 'Le serveur est en cours de mise à jour, veuillez réessayer dans quelques minutes.'
    }
  },
  de: {
    refresh: {
      txt: 'Eine neue Version ist verfügbar!',
      reload: 'Neu laden'
    },
    mismatch: {
      txt: 'Der Server wird gerade aktualisiert, bitte versuchen Sie es in ein paar Minuten erneut.'
    }
  },
  es: {
    refresh: {
      txt: '¡Nueva versión disponible!',
      reload: 'Volver a cargar'
    },
    mismatch: {
      txt: 'El servidor está siendo actualizado, por favor inténtelo de nuevo en unos minutos.'
    }
  },
  it: {
    refresh: {
      txt: 'È disponibile una nuova versione!',
      reload: 'Aggiorna'
    },
    mismatch: {
      txt: 'Il server è in fase di aggiornamento, riprovare tra qualche minuto.'
    }
  },
  ru: {
    refresh: {
      txt: 'Доступна новая версия!',
      reload: 'Перезагрузить'
    },
    mismatch: {
      txt: 'Сервер обновляется, пожалуйста, повторите попытку через несколько минут.'
    }
  },
  zh: {
    refresh: {
      txt: '新版本已推出！',
      reload: '重新加载'
    },
    mismatch: {
      txt: '服务器正在更新，请稍后再试。'
    }
  },
  zht: {
    refresh: {
      txt: '新版已推出！',
      reload: '重新載入'
    },
    mismatch: {
      txt: '伺服器正在更新中，請過幾分鐘後再試。'
    }
  },
};

const serviceWorkerHandler = {};

function ServiceWorkerLoader(props) {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [registration, setRegistration] = useState(null);
  const ln = navigator.language.substring(0, 2);
  const textsI18n = (ln in texts) ? texts[ln] : texts.en;
  serviceWorkerHandler.setShowReload = setShowReload;

  const onSWUpdate = (registration) => {
    setShowReload('refresh');
    setWaitingWorker(registration.waiting);
  };
  const onSWRegistration = (registration) => {
    setRegistration(registration);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate, onRegistration: onSWRegistration });
  }, []);
  const reloadPage = () => {
    if (showReload === 'refresh-mismatch') {
      localStorage.setItem('incompatible-version', Date.now());
    }
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
      window.location.reload(true);
    }
    else if (registration) {
      registration.unregister().then((boolean) => {
        window.location.reload(true);
      });
    }
    else {
      window.location.reload(true);
    }
    
  };
  if (!showReload) return props.children;
  return (
    <>
      <MessageBox type="warning" context="full" onClick={reloadPage}>
        { textsI18n[showReload === 'mismatch' ? 'mismatch' : 'refresh'].txt }
        <hr/>
        <button className="btn" type="button" onClick={reloadPage}>{ textsI18n.refresh.reload }</button>
        
      </MessageBox>
      { props.children }
    </>
    
  );
}

export { serviceWorkerHandler };
export default ServiceWorkerLoader;
