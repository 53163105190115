import React from 'react';
import { withTranslation } from 'react-i18next';
import Dropdown from 'components/Dropdown';
import Filter from 'components/filters/Filter';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.check = true;
  }

  reset = () => {
    this.props.productsPage.resetFilters();
  };

  render() {
    const { t } = this.props;

    const {
      filter_dial_color,
      filter_diameter,
      filter_family,
      filter_gender,
      filter_movement_type,
      filter_strap_type,
      filter_subfamily,
      filter_case_material,
    } = this.props.meta;
    const filter_subfamilies = [];
    const separated = filter_subfamily.length > 22;
    const dropdownAttrs = {
      name: 'filters',
      title: t('Filter')
    };
    if (separated) {
      dropdownAttrs.state = 'large';
      const indexSeparator = Math.ceil(filter_subfamily.length / 2);
      filter_subfamilies.push(filter_subfamily.slice(0, indexSeparator));
      filter_subfamilies.push(filter_subfamily.slice(indexSeparator));
    }

    return (
      <Dropdown {...dropdownAttrs}>
        {this.props.productsPage.hasFilters() && (
          <button className="btn" type="button" onClick={this.reset}>{t('Reset filters')}</button>
        )}
        <div className="filters-list">
          {separated && (
            <>
              <div className="col">
                <Filter id="filter_subfamily" filter={filter_subfamilies[0]} productsPage={this.props.productsPage} />
              </div>
              <div className="col">
                <Filter id="filter_subfamily" filter={filter_subfamilies[1]} productsPage={this.props.productsPage} />
              </div>
            </>
          )}
          {!separated && (
            <div className="col">
              <Filter id="filter_subfamily" filter={filter_subfamily} productsPage={this.props.productsPage} />
            </div>
          )}

          <div className="col">
            <Filter id="filter_gender" filter={filter_gender} productsPage={this.props.productsPage} />
            <Filter id="filter_diameter" filter={filter_diameter} productsPage={this.props.productsPage} />
            <Filter id="filter_case_material" filter={filter_case_material} productsPage={this.props.productsPage} />
            <Filter id="filter_dial_color" filter={filter_dial_color} productsPage={this.props.productsPage} />
          </div>
          <div className="col">
            <Filter id="filter_family" filter={filter_family} productsPage={this.props.productsPage} />
            <Filter id="filter_movement_type" filter={filter_movement_type} productsPage={this.props.productsPage} />
            <Filter id="filter_strap_type" filter={filter_strap_type} productsPage={this.props.productsPage} />
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(Filters);
